import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

interface IButton {
  icon: IconDefinition
  label: string
  callback: () => void
}

@Injectable({ providedIn: `root` })
export class HeaderService {
  public button = new BehaviorSubject<IButton>(undefined)
}
