<ng-container *ngIf="error$ | async as error; else welcome">
  <div class="container-fluid">
    <div class="row">
      <div class="col text-center text-4 my-3">
        {{ 'ERROR_HEADER' | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col text-center text-3">
        {{ (error.startsWith('ERROR') ? error : 'ERROR.' + error) | translate }}
      </div>
    </div>
  </div>
</ng-container>

<ng-template #welcome>
  <div class="container-fluid">
    <div class="row">
      <div class="col text-center text-4 my-3">
        {{ 'WELCOME_HEADER' | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col text-center text-3">
        {{ 'WELCOME_TEXT' | translate }}
      </div>
    </div>
  </div>
</ng-template>
