import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { Observable, catchError, map, of, tap } from 'rxjs'
import { AuthService } from '../shared/services/auth.service'

@Injectable({
  providedIn: `root`
})
export class AuthGuard  {
  constructor (
    private router: Router,
    private authService: AuthService
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canActivate (next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean> | Promise<boolean> | boolean {
    const token = this.authService.token.value
    const permissions = this.authService.permissions.value

    // not logged in so redirect to login page
    if (!token) {
      this.router.navigate([`/login`], { queryParams: { redirectTo: state.url } })
      return false
    }

    if (!permissions.length) {
      return this.authService.getPermissions().pipe(
        map(p => this.canAccess(state.url, p)),
        tap(canAccess => {
          if (!canAccess) {
            this.router.navigate(
              [`/error`],
              { queryParams: { error: `PERMISSION_DENIED` } })
          }
        }),
        catchError(() => {
          this.router.navigateByUrl(`/login`)
          return of(false)
        })
      )
    } else {
      return this.canAccess(state.url, permissions)
    }
  }

  private canAccess (url: string, permissions: string[]): boolean {
    if (url === `/eloaded`) {
      return permissions.includes(`PUBLIC.ELOADED`)
    } else if (url.startsWith(`/station/charge`)) {
      return permissions.includes(`PUBLIC.STATION`) || permissions.includes(`PUBLIC.ELOADED`)
    } else if (url.startsWith(`/station`)) {
      return permissions.includes(`PUBLIC.STATION`)
    }
    return true
  }
}
