import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ErrorComponent } from './error/error.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { NgxChartsModule } from '@swimlane/ngx-charts'
import { ProgressCircleModule } from './shared/components/progress-circle/progress-circle.module'
import { FormsModule } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { JwtInterceptor } from './helpers/jwt.interceptor'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { DarkModeSwitchComponentModule } from './shared/components/dark-mode-switch/dark-mode-switch.module'

export function HttpLoaderFactory (http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http)
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    NgxChartsModule,
    ProgressCircleModule,
    DarkModeSwitchComponentModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule
  ],
  providers: [{
    multi: true,
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
