import { Component, Input } from '@angular/core'

export interface ProgressCircleStyle {
  size: number;
  strokeWidth: number;
  showText: boolean;
}

@Component({
  selector: `eld-progress-circle`,
  styleUrls: [`./progress-circle.component.scss`],
  templateUrl: `./progress-circle.component.html`
})
export class ProgressCircleComponent {
  @Input() public icon?: `bolt` | `plug` | `spinner` | `danger`

  @Input() public value: number = 0

  @Input() public secondaryValue: number = 0

  @Input()
  public textSize: `small` | `large` = `large`

  @Input() public text

  @Input() public style!: ProgressCircleStyle

  @Input() public animation?: boolean

  public pi = Math.PI

  public get r (): number {
    const { size, strokeWidth } = this.style
    return (size / 2) - strokeWidth / 2 - 3
  }

  public get circ (): number {
    return this.r * 2 * Math.PI
  }

  public offset (perc: number): number {
    return this.circ - perc / 100 * this.circ
  }

  public get multilineText (): string[] {
    return this.text.split(`\n`).map(t => t.trim())
  }
}
