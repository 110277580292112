<div class="p-relative">
  <svg class="progress-ring" [attr.width]="style?.size" [attr.height]="style?.size">
    <ng-container *ngIf="icon === 'bolt'">
      <g opacity="0.6" clip-path="url(#clip0_2249_1234)" [attr.transform]="'translate(' + ((style?.size / 2) - (88.5 / 2)) + ', ' + ((style?.size / 2) - (118.5 / 2)) + ')'">
        <path d="M86.0276 65.576L27.2079 117.195C25.6169 118.606 23.8646 119.231 22.1353 119.231C20.6151 119.231 19.094 118.764 17.7981 117.817C15.0105 115.786 13.9936 112.089 15.3563 108.91L33.0876 67.4038H7.38075C4.30487 67.4038 1.5518 65.4913 0.470407 62.6029C-0.610299 59.7177 0.21101 56.46 2.52391 54.4286L61.3436 2.8099C63.9376 0.521653 67.7559 0.362008 70.5367 2.39367C73.3243 4.42533 74.3412 8.12239 72.9785 11.3014L55.2518 52.8092L80.961 52.8075C84.0369 52.8075 86.7899 54.72 87.8713 57.6084C89.1404 60.2776 88.3334 63.5399 86.0276 65.576Z" fill="#89BA17"/>
      </g>
    </ng-container>
    <ng-container *ngIf="icon === 'plug'">
      <g opacity="0.6" clip-path="url(#clip0_2249_2264)" [attr.transform]="'translate(' + ((style?.size / 2) - (88.5 / 2)) + ', ' + ((style?.size / 2) - (118.5 / 2)) + ')'">
        <path d="M74.0385 8.17307C74.0385 4.09633 70.7114 0.769226 66.6346 0.769226C62.5579 0.769226 59.2308 4.09633 59.2308 8.17307V30.3846H74.0385V8.17307ZM85.1442 37.5802H3.70192C1.64851 37.5802 0 39.2287 0 41.2822V48.686C0 50.7394 1.64851 52.3879 3.70192 52.3879H7.40385V60C7.40385 77.5841 19.7822 92.762 37.0192 96.2788V119.231H51.8269V96.2673C69.064 92.762 81.4423 77.5841 81.4423 60V52.3879H85.1442C87.1976 52.3879 88.8462 50.7394 88.8462 48.686V41.2822C88.8462 39.4312 87.2034 37.5802 85.1442 37.5802ZM29.6154 8.17307C29.6154 4.09633 26.2883 0.769226 22.2115 0.769226C18.1348 0.769226 14.8077 4.09633 14.8077 8.17307V30.3846H29.6154V8.17307Z" fill="#89BA17"/>
      </g>        
    </ng-container>
    <ng-container *ngIf="icon === 'spinner'">
      <g clip-path="url(#clip0_2249_1765)" [attr.transform]="'translate(' + ((style?.size / 2) - (118.5 / 2)) + ', ' + ((style?.size / 2) - (118.5 / 2)) + ')'">
        <path d="M22.9808 60C22.9808 53.8687 18.0064 48.8942 11.8751 48.8942C5.74375 48.8942 0.769287 53.8687 0.769287 60C0.769287 66.1313 5.74375 71.1058 11.8751 71.1058C18.0064 71.1058 22.9808 66.1313 22.9808 60ZM25.9655 14.8574C19.8342 14.8574 14.8574 19.8295 14.8574 25.9608C14.8574 32.0921 19.8319 37.0712 25.9655 37.0712C32.0991 37.0712 37.069 32.0944 37.069 25.9631C37.069 19.8318 32.0968 14.8574 25.9655 14.8574ZM25.9655 82.9288C19.8342 82.9288 14.8574 87.9056 14.8574 94.0369C14.8574 100.168 19.8319 105.14 25.9655 105.14C32.0991 105.14 37.069 100.168 37.069 94.0369C37.069 87.9056 32.0968 82.9288 25.9655 82.9288ZM60.0001 97.0192C53.8688 97.0192 48.8943 101.994 48.8943 108.125C48.8943 114.256 53.8688 119.231 60.0001 119.231C66.1314 119.231 71.1058 114.256 71.1058 108.125C71.1058 101.994 66.1314 97.0192 60.0001 97.0192ZM108.125 48.8942C101.994 48.8942 97.0193 53.8687 97.0193 60C97.0193 66.1313 101.994 71.1058 108.125 71.1058C114.256 71.1058 119.231 66.1313 119.231 60C119.231 53.8687 114.256 48.8942 108.125 48.8942ZM94.0346 82.9288C87.9033 82.9288 82.9312 87.9056 82.9312 94.0369C82.9312 100.168 87.9033 105.14 94.0346 105.14C100.166 105.14 105.143 100.168 105.143 94.0369C105.143 87.9056 100.166 82.9288 94.0346 82.9288ZM60.0001 0.769226C53.8688 0.769226 48.8943 5.74369 48.8943 11.875C48.8943 18.0063 53.8688 22.9808 60.0001 22.9808C66.1314 22.9808 71.1058 18.0063 71.1058 11.875C71.1058 5.74369 66.1314 0.769226 60.0001 0.769226Z" fill="#E5A54B"/>
      </g>
    </ng-container>
    <ng-container *ngIf="icon === 'danger'">
      <g clip-path="url(#clip0_2249_2813)" [attr.transform]="'translate(' + ((style?.size / 2) - (118.5 / 2)) + ', ' + ((style?.size / 2) - (118.5 / 2)) + ')'">
        <path d="M117.912 96.5583L68.5608 12.3395C64.7825 5.86118 55.2478 5.86118 51.444 12.3395L2.1159 96.5583C-1.68088 103.014 3.04809 111.135 10.6581 111.135H109.361C116.94 111.135 121.683 103.037 117.912 96.5583ZM54.4472 38.9471C54.4472 35.8815 56.9345 33.3942 60.0001 33.3942C63.0658 33.3942 65.553 35.893 65.553 38.9471V68.5625C65.553 71.6282 63.0658 74.1154 60.2084 74.1154C57.3509 74.1154 54.4472 71.6397 54.4472 68.5625V38.9471ZM60.0001 96.3269C55.9835 96.3269 52.7258 93.0692 52.7258 89.0527C52.7258 85.0361 55.9812 81.7784 60.0001 81.7784C64.019 81.7784 67.2744 85.0361 67.2744 89.0527C67.2651 93.0646 64.026 96.3269 60.0001 96.3269Z" fill="#D9534F"/>
      </g>
    </ng-container>
    <!-- animation block -->
    <g class="progress-ring-cont" *ngIf="animation">
      <circle
        class="pulsating-circle"
        stroke="#c9c9cf"
        stroke-linecap="round"
        [attr.stroke-dasharray]="circ + ' ' + circ"
        [attr.stroke-width]="style?.strokeWidth"
        fill="transparent"
        [attr.r]="r"
        [attr.cx]="(style?.size || 0) / 2"
        [attr.cy]="(style?.size || 0) / 2"/>
    </g>
    <!-- background circle -->
    <g class="progress-ring-cont">
      <circle
        class="bg-circle"
        stroke-linecap="round"
        fill="transparent"
        [attr.stroke-dasharray]="circ + ' ' + circ"
        [attr.stroke-width]="style?.strokeWidth"
        [attr.r]="r"
        [attr.cx]="(style?.size || 0) / 2"
        [attr.cy]="(style?.size || 0) / 2"/>
    </g>
    <!-- secondaryValue circle -->
    <g class="progress-ring-cont" *ngIf="secondaryValue">
      <circle
        class="autostop-circle-border"
        stroke-linecap="round"
        [attr.stroke-dasharray]="circ + ' ' + circ"
        [attr.stroke-dashoffset]="secondaryValue ? offset(secondaryValue) : undefined"
        [attr.stroke-width]="style?.strokeWidth + 6"
        fill="transparent"
        [attr.r]="r"
        [attr.cx]="(style?.size || 0) / 2"
        [attr.cy]="(style?.size || 0) / 2"/>
      <circle
        class="autostop-circle"
        stroke-linecap="round"
        [attr.stroke-dasharray]="circ + ' ' + circ"
        [attr.stroke-dashoffset]="secondaryValue ? offset(secondaryValue) : undefined"
        [attr.stroke-width]="style?.strokeWidth"
        fill="transparent"
        [attr.r]="r"
        [attr.cx]="(style?.size || 0) / 2"
        [attr.cy]="(style?.size || 0) / 2"/>
    </g>
    <!-- value circle -->
    <g class="progress-ring-cont" *ngIf="value">
      <circle
        class="value-circle-border"
        stroke-linecap="round"
        [attr.stroke-dasharray]="circ + ' ' + circ"
        [attr.stroke-dashoffset]="value ? offset(value) : undefined"
        [attr.stroke-width]="style?.strokeWidth + 6"
        fill="transparent"
        [attr.r]="r"
        [attr.cx]="(style?.size || 0) / 2"
        [attr.cy]="(style?.size || 0) / 2"/>
      <circle
        class="value-circle"
        stroke-linecap="round"
        [attr.stroke-dasharray]="circ + ' ' + circ"
        [attr.stroke-dashoffset]="value ? offset(value) : undefined"
        [attr.stroke-width]="style?.strokeWidth"
        fill="transparent"
        [attr.r]="r"
        [attr.cx]="(style?.size || 0) / 2"
        [attr.cy]="(style?.size || 0) / 2"/>
    </g>
  </svg>
  <div [style.width.px]="style?.size" [style.height.px]="style?.size" class="p-absolute top-0 left-0 d-flex flex-column justify-content-center">
    <span [ngClass]="{
      'text-8': textSize === 'large',
      'text-4': textSize === 'small'
    }" class="circle-text text-center text-secondary">
      <ng-content select="[text]"></ng-content>
    </span>
  </div>
</div>