import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ProgressCircleModule } from '../progress-circle/progress-circle.module'
import { StatusComponent } from './status.component'
import { ProgressBarModule } from '../progress-bar/progress-bar.module'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [
    StatusComponent
  ],
  imports: [
    CommonModule,
    ProgressCircleModule,
    ProgressBarModule,
    TranslateModule
  ],
  exports: [
    CommonModule,
    StatusComponent
  ]
})
export class StatusModule { }
