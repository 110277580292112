import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ErrorComponent } from './error/error.component'
import { AuthGuard } from './helpers/auth.guard'

const routes: Routes = [
  { path: `login`, loadChildren: () => import(`./login/login.module`).then(m => m.LoginModule) },
  { path: `eloaded`, canActivate: [AuthGuard], loadChildren: () => import(`./eloaded/eloaded.module`).then(m => m.EloadedModule) },
  { path: `station/charge`, canActivate: [AuthGuard], loadChildren: () => import(`./charging/charging.module`).then(m => m.ChargingModule) },
  { path: `station`, canActivate: [AuthGuard], loadChildren: () => import(`./station/station.module`).then(m => m.StationModule) },
  { path: `charge`, loadChildren: () => import(`./charging/charging.module`).then(m => m.ChargingModule) },
  { path: ``, redirectTo: `eloaded`, pathMatch: `full` },
  { path: `**`, component: ErrorComponent } // Error Page
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
