import { Component } from '@angular/core'
import { ThemeService } from '../../services/theme.service'
import { faMoon } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: `eld-dark-mode-switch`,
  templateUrl: `./dark-mode-switch.component.html`,
  styleUrl: `./dark-mode-switch.component.scss`
})
export class DarkModeSwitchComponent {
  protected faMoon = faMoon

  constructor (protected themeService: ThemeService) {}

  public onSwitchMode () {
    this.themeService.theme.next(
      this.themeService.theme.value === `light` ? `dark` : `light`
    )
  }
}
