<div class="row g-0 d-flex flex-grow-1">
  <div class="col d-flex flex-column">
    <div class="row m-0">
      <div class="col text-center text-3 lineh-4-5 text-secondary">
        <ng-content select="[valueText]"></ng-content>
      </div>
    </div>

    <div class="row m-0 flex-grow-1">
      <div class="col text-center text-2 lineh-3">
        <ng-content select="[additionalText]"></ng-content>
      </div>
    </div>

    <div class="row m-0">
      <div class="col d-flex">
        <progress [ngClass]="{ animation }" [value]="value" max="100"></progress>
      </div>
    </div>
    <div class="row m-0">
      <div class="col text-center text-3 lineh-4-5 text-secondary">
        <ng-content select="[label]"></ng-content>
      </div>
    </div>
  </div>
</div>
