/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { AuthService } from '../shared/services/auth.service'

/**
 * @ngdoc interceptor
 * @name JwtInterceptor
 * @desc Add token to every request
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   * @function
   * @name intercept
   * @desc Add header with JWT token to the HTTP request
   * @param { HttpRequest<any> } request - outgoing request
   * @param { HttpHandler } next - next step of HTTPRequest
   * @returns { Observable<HttpEvent<any>> } - Given request with Bearer Token header
   */

  constructor (private authService: AuthService) {}

  public intercept (request: HttpRequest<any>, next: HttpHandler)
  : Observable<HttpEvent<any>> {
    const token = this.authService?.token.value
    if (token) {
      request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } })
    }
    return next.handle(request)
  }
}
