import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { StatusModule } from 'src/app/shared/components/status/status.module'

import { RouterModule } from '@angular/router'
import { DarkModeSwitchComponent } from './dark-mode-switch.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

@NgModule({
  declarations: [DarkModeSwitchComponent],
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,

    // Shared Modules
    StatusModule,
    FontAwesomeModule
  ],
  exports: [DarkModeSwitchComponent]
})
export class DarkModeSwitchComponentModule { }
