import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ProgressCircleComponent } from './progress-circle.component'
import { TranslateModule } from '@ngx-translate/core'
import { SecondsToTimePipe } from './pipe/seconds-to-time.pipe'

@NgModule({
  declarations: [
    ProgressCircleComponent,
    SecondsToTimePipe
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    CommonModule,
    ProgressCircleComponent,
    SecondsToTimePipe
  ]
})
export class ProgressCircleModule { }
