import { Component, Input } from '@angular/core'
import { ChargingData } from 'src/app/interface/charging-data'
import { IChargeInfoExtra } from '../../services/station.service'

@Component({
  selector: `eld-status`,
  styleUrls: [`./status.component.scss`],
  templateUrl: `./status.component.html`
})
export class StatusComponent {
  @Input()
  public statusData: ChargingData & IChargeInfoExtra

  @Input() public publicDisplay!: boolean

  protected getAveragePower (charge: ChargingData): number {
    const { energy, startDate, stopDate } = charge

    if (!startDate || energy === undefined) {
      return
    }
    // Calculate the time charging
    const start = new Date(startDate)
    const stop = stopDate ? new Date(stopDate) : new Date()

    const timeChargingInMs = stop.getTime() - start.getTime()
    const timeChargingInH = timeChargingInMs / 1000 / 60 / 60

    // Calculate average power
    return Math.round(energy / timeChargingInH * 10) / 10
  }
}
