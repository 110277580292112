import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { HeaderService } from './shared/services/header.service'
import { ThemeService } from './shared/services/theme.service'

@Component({
  selector: `eld-root`,
  styleUrls: [`./app.component.scss`],
  templateUrl: `./app.component.html`
})
export class AppComponent {
  public langs = [{
    name: `Deutsch`,
    value: `de`
  }, {
    name: `English`,
    value: `en`
  }]

  public _currentLang = this.langs.find(({ value }) => value === `de`)

  constructor (
    public headerService: HeaderService,
    public themeService: ThemeService,
    public translate: TranslateService
  ) {
    translate.setDefaultLang(this.currentLang.value)

    const lang = localStorage.getItem(`lang`) || translate.getBrowserLang()
    const langAvailable = this.langs.find(({ value }) => value === lang)

    if (langAvailable) {
      this.currentLang = langAvailable
    }
    localStorage.setItem(`lang`, this.translate.currentLang)
  }

  public set currentLang (l: {name: string, value: string }) {
    this._currentLang = l
    this.translate.use(this.currentLang.value)
  }

  public get currentLang () {
    return this._currentLang
  }

}
