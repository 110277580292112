import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

/**
  * @ngdoc pipe
  * @name SecondsToTimePipe
  * @desc Transform given seconds in readable string
  */
@Pipe({ name: `secondsToTime` })
export class SecondsToTimePipe implements PipeTransform {
  public times = {
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1
  }

  public timeUnits = {
    day: `d`,
    hour: `h`,
    minute: `min`,
    second: `s`
  }

  constructor (private _translate: TranslateService) { }

  /**
    * @function
    * @name transform
    * @desc Transform given seconds in readable string
    * @param { number } seconds - Time in seconds
    * @returns { string } - Formatted string
    */
  public transform (seconds: number): string {
    let counter = 0
    let timeString = ``
    const conversion = (key: string) => Math.floor(seconds / this.times[key])

    for (const key in this.times) {
      const conv = conversion(key)
      if (conv > 0 && (key !== `second` || !timeString.length) && (counter < 2)) {
        counter++
        timeString += `${conv} ${this.timeUnits[key]} `
        seconds = seconds - this.times[key] * conv

        if (key === `second` && counter === 1) {
          timeString = `< 1 min`
        }
      }
    }

    if (!timeString) {
      return `< 1 min`
    }
    return timeString.trim()
  }
}
