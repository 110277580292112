import { Component, Input } from '@angular/core'

@Component({
  selector: `eld-progress-bar`,
  styleUrls: [`./progress-bar.component.scss`],
  templateUrl: `./progress-bar.component.html`
})
export class ProgressBarComponent {
  @Input() public value: number = 100

  @Input() public animation: boolean = false
}
